export const DATA_LIST_TEAM = [
  {
    id: 1,
    name: 'GERMANY',
    logo: '/images/game/teams/GERMANY.png',
  },
  {
    id: 2,
    name: 'SCOTLAND',
    logo: '/images/game/teams/SCOTLAND.png',
  },
  {
    id: 3,
    name: 'HUNGARY',
    logo: '/images/game/teams/HUNGARY.png',
  },
  {
    id: 4,
    name: 'SWITZERLAND',
    logo: '/images/game/teams/Switzerland.png',
  },
  {
    id: 5,
    name: 'SPAIN',
    logo: '/images/game/teams/SPAIN.png',
  },
  {
    id: 6,
    name: 'CROATIA',
    logo: '/images/game/teams/CROATIA.png',
  },
  {
    id: 7,
    name: 'ITALIA',
    logo: '/images/game/teams/ITALIA.png',
  },
  {
    id: 8,
    name: 'ALBANIA',
    logo: '/images/game/teams/ALBANIA.png',
  },
  {
    id: 9,
    name: 'SLOVENIA',
    logo: '/images/game/teams/SLOVENIA.png',
  },
  {
    id: 10,
    name: 'DENMARK',
    logo: '/images/game/teams/DENMARK.png',
  },
  {
    id: 11,
    name: 'SERBIA',
    logo: '/images/game/teams/SERBIA.png',
  },
  {
    id: 12,
    name: 'ENGLAND',
    logo: '/images/game/teams/England.png',
  },
  {
    id: 13,
    name: 'POLAND',
    logo: '/images/game/teams/POLAND.png',
  },
  {
    id: 14,
    name: 'HOLLAND',
    logo: '/images/game/teams/Holland.png',
  },
  {
    id: 15,
    name: 'AUSTRIA',
    logo: '/images/game/teams/AUSTRIA.png',
  },
  {
    id: 16,
    name: 'FRANCE',
    logo: '/images/game/teams/FRANCE.png',
  },
  {
    id: 17,
    name: 'BELGIUM',
    logo: '/images/game/teams/BELGIUM.png',
  },
  {
    id: 18,
    name: 'SLOVAKIA',
    logo: '/images/game/teams/SLOVAKIA.png',
  },
  {
    id: 19,
    name: 'ROMANIA',
    logo: '/images/game/teams/ROMANIA.png',
  },
  {
    id: 20,
    name: 'UKRAINE',
    logo: '/images/game/teams/UKRAINE.png',
  },
  {
    id: 21,
    name: 'TURKEY',
    logo: '/images/game/teams/TURKEY.png',
  },
  {
    id: 22,
    name: 'GEORGIA',
    logo: '/images/game/teams/GEORIGIA.png',
  },
  {
    id: 23,
    name: 'PORTUGAL',
    logo: '/images/game/teams/PORTUGAL.png',
  },
  {
    id: 24,
    name: 'CZECH REPUBLIC',
    logo: '/images/game/teams/Czech Republic.png',
  },
  {
    id: 25,
    name: 'REAL MADRID',
    logo: '/images/game/teams/real madrid.png',
  },
  {
    id: 26,
    name: 'DORTMUND',
    logo: '/images/game/teams/dortmund.png',
  },
  {
    id: 27,
    name: 'BOSNIA AND HERZEGOVINA',
    logo: '/images/game/teams/Bosnia and Herzegovina.png',
  },
  {
    id: 28,
    name: 'NORTH MACEDONIA',
    logo: '/images/game/teams/North Macedonia.png',
  },
  {
    id: 29,
    name: 'ARMENIA',
    logo: '/images/game/teams/Armenia.png',
  },
  {
    id: 30,
    name: 'FINLAND',
    logo: '/images/game/teams/finland.png',
  },
  {
    id: 31,
    name: 'SWEDEN',
    logo: '/images/game/teams/Sweden.png',
  },
  {
    id: 32,
    name: 'ANDORRA',
    logo: '/images/game/teams/Andorra.png',
  },
  {
    id: 33,
    name: 'LUXEMBOURG',
    logo: '/images/game/teams/Luxembourg.png',
  },
  {
    id: 34,
    name: 'MONTENEGRO',
    logo: '/images/game/teams/Montenegro.png',
  },
  {
    id: 35,
    name: 'CANADA',
    logo: '/images/game/teams/Canada.png',
  },
]
