import { useCallback } from 'react'
import fetchHelper from 'helpers/FetchHelper'
import { API_PAYX } from 'config/constants/endpoints'

const useScore = () => {
  const getListScore = useCallback((payload, cbs?: any, cbe?: any) => {
    try {
      fetchHelper
        .fetch(`${API_PAYX}/scores/search`, {
          method: 'POST',
          body: JSON.stringify(payload),
        })
        .then(([resp, status]: any) => {
          if (status === 201 || status === 200) {
            if (cbs) cbs(resp.data)
          } else if (cbe) cbe(resp)
        })
    } catch (error) {
      console.error('getListScore', error)
      cbe()
    }
  }, [])

  const createScore = useCallback((payload: any, cbs?: any, cbe?: any) => {
    return new Promise((resolve, reject) => {
      try {
        fetchHelper
          .fetch(`${API_PAYX}/scores`, {
            method: 'POST',
            body: JSON.stringify(payload),
          })
          .then(([resp, status]: any) => {
            if (status === 201 || status === 200) {
              if (cbs) cbs(resp.data)
              resolve(resp.data)
            } else if (cbe) {
              cbe(resp)
              reject()
            }
          })
      } catch (error) {
        console.error('createScore', error)
        cbe()
        reject()
      }
    })
    
  }, [])

  const editScore = useCallback((id: string, payload: any, cbs?: any, cbe?: any) => {
    return new Promise((resolve, reject) => {
      try {
        fetchHelper
          .fetch(`${API_PAYX}/scores/${id}`, {
            method: 'PATCH',
            body: JSON.stringify(payload),
          })
          .then(([resp, status]: any) => {
            if (status === 201 || status === 200) {
              if (cbs) cbs(resp.data)
                resolve(resp.data)
            } else if (cbe) {
              cbe(resp)
              reject()
            }
          })
      } catch (error) {
        console.error('editScore', error)
        cbe()
        reject()
      }
    })
  }, [])

  const deleteScore = useCallback((id: string, cbs?: any, cbe?: any) => {
    try {
      fetchHelper
        .fetch(`${API_PAYX}/scores/${id}`, {
          method: 'DELETE',
        })
        .then(([resp, status]: any) => {
          if (status === 201 || status === 200) {
            if (cbs) cbs(resp)
          } else if (cbe) cbe(resp)
        })
    } catch (error) {
      console.error('deleteScore', error)
      cbe()
    }
  }, [])

  return {createScore, editScore, deleteScore, getListScore}
}

export default useScore
