/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Col, DatePicker, Form, InputNumber, Modal, Popconfirm, Row, Select, Spin } from 'antd'
import { DATA_LIST_TEAM } from 'config/constants/game'
import useGetCreateGame from 'hooks/useGetCreateGame'
import useGetEditGame from 'hooks/useGetEditGame'
import useGetDeleteGame from 'hooks/useGetDeleteGame'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import useScore from 'hooks/useScore'
import useToast from 'hooks/useToast'
import ModalScore from '../ModalScore'
import ModalFinalScore from '../ModalFinalScore'

const WrapModalGame = styled.div`
  .modal-nft-title {
    color: #f6f8fa;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    margin-bottom: 20px;

    ${({ theme }) => theme.mediaQueries.lg} {
      font-size: 20px;
      line-height: 32px;
    }
  }

  .modal-nft-wrap-content {
    .btn-confirm {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 16px;

      button {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        height: 48px;
        width: 175px;
        box-shadow: 0px 0px 12px 0px rgba(191, 151, 255, 0.24) inset;
        border-radius: 34778.336px;
        border: 1px solid #6e9f96;
        background: rgba(0, 0, 0, 0.65);
        backdrop-filter: blur(1.7389342784881592px);

        &:nth-child(1) {
          background: red;
          backdrop-filter: none;
          width: 135px;
        }

        &:nth-child(2) {
          background: transparent;
          backdrop-filter: none;
          width: 135px;
        }

        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
  }

  .ant-pagination {
    text-align: right;
  }

  .table-row {
    display: flex;
    justify-content: flex-end;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    &:last-child {
      margin-bottom: 15px;
    }

    .table-cell {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 75px;
      padding: 4px;
      border-radius: 4px;
      background: rgba(255, 255, 255, 0.2);
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 5px;
      }

      p {
        font-size: 14px;
        margin-right: 4px;
      }

      span {
        color: #ffd100;
        font-size: 10px;
        border-radius: 100px;
        background: rgba(255, 255, 255, 0.1);
        padding: 0px 3px;
      }
    }
  }

  .ant-input-number,
  .ant-picker {
    width: 100%;
  }

  input,
  .ant-select-selector {
    align-items: center;
    height: 50px !important;
  }

  .ant-picker {
    input {
      height: 40px !important;
    }
  }
`

const ModalGame = ({
  modal,
  setModal,
  setRefreshGame,
}: {
  modal: any
  setModal: (input: any) => void
  setRefreshGame: (input: any) => void
}) => {
  const [form] = Form.useForm()
  const { toastSuccess, toastError } = useToast()

  const [loading, setLoading] = useState<boolean>(false)
  const [modalScore, setModalScore] = useState<{
    toggle: boolean
    data: any
  }>({
    toggle: false,
    data: null,
  })
  const [modalFinalScore, setModalFinalScore] = useState<{
    toggle: boolean
    data: any
  }>({
    toggle: false,
    data: null,
  })

  const [initScores, setInitScores] = useState([])
  const [refreshScore, setRefreshScore] = useState(new Date())

  const editGame = useGetEditGame()
  const createGame = useGetCreateGame()
  const deleteGame = useGetDeleteGame()

  const onCancel = useCallback(() => {
    setModal({
      toggle: false,
      type: 'Create',
      data: null,
    })
    form.resetFields()
    setLoading(false)
  }, [form, setModal])

  const { createScore, editScore, getListScore, deleteScore } = useScore()

  useEffect(() => {
    if (!modal?.data?.id) {
      setInitScores([])
      setModalScore((val) => {
        return { ...val, data: { scores: [] } }
      })
      return
    }
    getListScore(
      {
        skip: 0,
        limit: 100,
        filter: [
          {
            name: 'team',
            value: modal?.data?.id,
            operator: 'eq',
          },
          {
            name: 'status',
            value: 'SHOW',
            operator: 'eq',
          },
        ],
      },
      (data) => {
        setInitScores(data.docs)
        setModalScore((val) => {
          return { ...val, data: { scores: data.docs } }
        })
      },
    )
  }, [getListScore, modal?.data?.id, refreshScore])

  const onSubmitScore = useCallback(
    async (teamId: string, submitScores?: any) => {
      const { data } = modalScore
      const scores = submitScores || data?.scores
      if (!scores?.length) return

      const deleteScores = initScores.filter((item) => !scores.find((score) => score.id === item.id))

      const { createScores, editScores } = scores.reduce((obj: any, item) => {
        const newObj = { ...obj }
        const type = item.id ? 'editScores' : 'createScores'
        newObj[type] = newObj[type] || []

        if (type === 'editScores') {
          const foundInitScore = initScores.find((score) => score.id === item.id)
          const keys = Object.keys(item)

          if (!foundInitScore || keys.every((key) => foundInitScore[key] === item[key])) return newObj

          newObj[type].push(item)
        } else newObj[type].push(item)

        return newObj
      }, {})

      if (deleteScores?.length) {
        await Promise.all(deleteScores.map((item) => deleteScore(item.id)))
      }
      if (editScores?.length) {
        await Promise.all(editScores.map((item) => editScore(item.id, { ...item, teamId })))
      }
      if (createScores?.length) {
        await Promise.all(createScores.map((item) => createScore({ ...item, teamId })))
      }

      setRefreshScore(new Date())
    },
    [createScore, deleteScore, editScore, initScores, modalScore],
  )

  const onFinish = useCallback(
    (values: any) => {
      const formattedValues = {
        ...values,
        startMatch: values.startMatch ? Math.floor(moment.utc(values.startMatch).valueOf() / 1000) : null,
        startPredict: values.startPredict ? Math.floor(moment.utc(values.startPredict).valueOf() / 1000) : null,
        endPredict: values.endPredict ? Math.floor(moment.utc(values.endPredict).valueOf() / 1000) : null,
        teamALogo: DATA_LIST_TEAM.find((item) => item.name === values.teamA)?.logo,
        teamBLogo: DATA_LIST_TEAM.find((item) => item.name === values.teamB)?.logo,
        scoreA: values.scoreA ?? 0,
        scoreB: values.scoreB ?? 0,
      }
      setLoading(true)

      if (modal?.type === 'Edit') {
        editGame(
          modal?.data?.id,
          formattedValues,
          () => {
            setRefreshGame(new Date())
            toastSuccess('Edit successfully!')
            onCancel()
          },
          () => {
            toastError('Edit failed, please try again later!!')
          },
        )

        return
      }

      createGame(
        formattedValues,
        (data) => {
          onSubmitScore(data?.id)
          setRefreshGame(new Date())
          onCancel()
          toastSuccess('Create successfully!')
        },
        () => {
          toastError('Create failed, please try again later!!')
        },
      )
    },
    [
      createGame,
      editGame,
      modal?.data?.id,
      modal?.type,
      onCancel,
      onSubmitScore,
      setRefreshGame,
      toastError,
      toastSuccess,
    ],
  )

  const confirmDelete = useCallback(() => {
    deleteGame(
      modal?.data?.id,
      () => {
        toastSuccess('Delete successfully!')
        onCancel()
        setRefreshGame(new Date())
      },
      () => {
        toastSuccess('Delete failed, please try again later!!')
      },
    )
  }, [deleteGame, modal?.data?.id, onCancel, setRefreshGame, toastSuccess])

  useEffect(() => {
    if (modal?.type === 'Edit') {
      form.setFieldsValue({
        teamA: modal?.data?.teamA,
        teamB: modal?.data?.teamB,
        winRateTeamA: modal?.data?.winRateTeamA,
        winRateTeamB: modal?.data?.winRateTeamB,
        scoreA: modal?.data?.scoreA,
        scoreB: modal?.data?.scoreB,
        drawRate: modal?.data?.drawRate,
        maxDrawBnB: modal?.data?.maxDrawBnB,
        maxDrawBunny: modal?.data?.maxDrawBunny,
        maxDrawChz: modal?.data?.maxDrawChz,
        maxDrawBunnyChz: modal?.data?.maxDrawBunnyChz,
        maxTeamABnB: modal?.data?.maxTeamABnB,
        maxTeamABunny: modal?.data?.maxTeamABunny,
        maxTeamAChz: modal?.data?.maxTeamAChz,
        maxTeamABunnyChz: modal?.data?.maxTeamABunnyChz,
        maxTeamBBnB: modal?.data?.maxTeamBBnB,
        maxTeamBBunny: modal?.data?.maxTeamBBunny,
        maxTeamBChz: modal?.data?.maxTeamBChz,
        maxTeamBBunnyChz: modal?.data?.maxTeamBBunnyChz,
        startMatch: modal?.data?.startMatch ? moment.utc(modal?.data?.startMatch * 1000) : null,
        startPredict: modal?.data?.startPredict ? moment.utc(modal?.data?.startPredict * 1000) : null,
        endPredict: modal?.data?.endPredict ? moment.utc(modal?.data?.endPredict * 1000) : null,
      })
    }
  }, [form, modal])
  const isEditScore = useMemo(() => !!modalScore?.data?.scores?.length, [modalScore?.data?.scores?.length])

  return (
    <Modal title="" centered open={modal?.toggle} footer={false} onCancel={onCancel} width={1200}>
      <Form form={form} name="control-hooks" onFinish={onFinish} layout="vertical">
        <WrapModalGame>
          <div className="modal-nft-title">{modal?.type} Game</div>

          <div className="modal-nft-wrap-content">
            <div className="modal-nft-content">
              <div className="table">
                <Row gutter={[30, 0]}>
                  <Col span={24} lg={{ span: 6 }}>
                    <Form.Item name="teamA" label="Team A" rules={[{ required: true }]}>
                      <Select disabled={modal?.data?.isFinal || modal?.data?.id}>
                        {DATA_LIST_TEAM?.sort((a, b) => a.name.localeCompare(b.name))?.map((item) => (
                          <Select.Option value={item?.name}>{item?.name}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={{ span: 6 }}>
                    <Form.Item name="winRateTeamA" label="Win Rate Team A" rules={[{ required: true }]}>
                      <InputNumber disabled={modal?.data?.isFinal} />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={{ span: 6 }}>
                    <Form.Item name="maxTeamABunny" label="Max Team A Bunny" rules={[{ required: true }]}>
                      <InputNumber disabled={modal?.data?.isFinal} />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={{ span: 6 }}>
                    <Form.Item name="maxTeamABnB" label="Max Team A BNB" rules={[{ required: true }]}>
                      <InputNumber disabled={modal?.data?.isFinal} />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={{ span: 6 }}>
                    <Form.Item name="maxTeamAChz" label="Max Team A CHZ" rules={[{ required: true }]}>
                      <InputNumber disabled={modal?.data?.isFinal} />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={{ span: 6 }}>
                    <Form.Item name="maxTeamABunnyChz" label="Max Team A Bunny CHZ" rules={[{ required: true }]}>
                      <InputNumber disabled={modal?.data?.isFinal} />
                    </Form.Item>
                  </Col>
                  {modal?.type === 'Edit' && (
                    <>
                      <Col span={24} lg={{ span: 6 }}>
                        <Form.Item name="scoreA" label="Score Team A" rules={[{ required: true }]}>
                          <InputNumber disabled={modal?.data?.isFinal} />
                        </Form.Item>
                      </Col>
                    </>
                  )}
                  <Col span={24} lg={{ span: 6 }}>
                    <Form.Item name="teamB" label="Team B" rules={[{ required: true }]}>
                      <Select disabled={modal?.data?.isFinal || modal?.data?.id}>
                        {DATA_LIST_TEAM?.sort((a, b) => a.name.localeCompare(b.name))?.map((item) => (
                          <Select.Option value={item?.name}>{item?.name}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={{ span: 6 }}>
                    <Form.Item name="winRateTeamB" label="Win Rate Team B" rules={[{ required: true }]}>
                      <InputNumber disabled={modal?.data?.isFinal} />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={{ span: 6 }}>
                    <Form.Item name="maxTeamBBunny" label="Max Team B Bunny" rules={[{ required: true }]}>
                      <InputNumber disabled={modal?.data?.isFinal} />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={{ span: 6 }}>
                    <Form.Item name="maxTeamBBnB" label="Max Team B BNB" rules={[{ required: true }]}>
                      <InputNumber disabled={modal?.data?.isFinal} />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={{ span: 6 }}>
                    <Form.Item name="maxTeamBChz" label="Max Team B CHZ" rules={[{ required: true }]}>
                      <InputNumber disabled={modal?.data?.isFinal} />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={{ span: 6 }}>
                    <Form.Item name="maxTeamBBunnyChz" label="Max Team B Bunny CHZ" rules={[{ required: true }]}>
                      <InputNumber disabled={modal?.data?.isFinal} />
                    </Form.Item>
                  </Col>
                  {modal?.type === 'Edit' && (
                    <>
                      <Col span={24} lg={{ span: 6 }}>
                        <Form.Item name="scoreB" label="Score Team B" rules={[{ required: true }]}>
                          <InputNumber disabled={modal?.data?.isFinal} />
                        </Form.Item>
                      </Col>
                    </>
                  )}
                  <Col span={24} lg={{ span: 6 }}>
                    <Form.Item name="drawRate" label="Draw Rate" rules={[{ required: true }]}>
                      <InputNumber disabled={modal?.data?.isFinal} />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={{ span: 6 }}>
                    <Form.Item name="maxDrawBnB" label="Max Draw BnB" rules={[{ required: true }]}>
                      <InputNumber disabled={modal?.data?.isFinal} />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={{ span: 6 }}>
                    <Form.Item name="maxDrawBunny" label="Max Draw Bunny" rules={[{ required: true }]}>
                      <InputNumber disabled={modal?.data?.isFinal} />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={{ span: 6 }}>
                    <Form.Item name="maxDrawChz" label="Max Draw Chz" rules={[{ required: true }]}>
                      <InputNumber disabled={modal?.data?.isFinal} />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={{ span: 6 }}>
                    <Form.Item name="maxDrawBunnyChz" label="Max Draw Bunny CHZ" rules={[{ required: true }]}>
                      <InputNumber disabled={modal?.data?.isFinal} />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={{ span: 6 }}>
                    <Form.Item name="startMatch" label="Start Match" rules={[{ required: true }]}>
                      <DatePicker showTime disabled={modal?.data?.isFinal} />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={{ span: 6 }}>
                    <Form.Item name="startPredict" label="Start Predict" rules={[{ required: true }]}>
                      <DatePicker showTime disabled={modal?.data?.isFinal} />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={{ span: 6 }}>
                    <Form.Item name="endPredict" label="End Predict" rules={[{ required: true }]}>
                      <DatePicker showTime disabled={modal?.data?.isFinal} />
                    </Form.Item>
                  </Col>
                  {modal?.data?.isFinal ? (
                    <Col span={24} lg={{ span: 6 }}>
                      <Form.Item label="View Score">
                        <Button
                          onClick={() => {
                            setModalScore((val) => ({
                              ...val,
                              toggle: true,
                            }))
                          }}
                          style={{ height: 50, width: '100%' }}
                        >
                          View Score
                        </Button>
                      </Form.Item>
                    </Col>
                  ) : (
                    <>
                      {modal?.data?.id && (
                        <Col span={24} lg={{ span: 6 }}>
                          <Form.Item label={isEditScore ? 'Edit Score' : 'Add Score'}>
                            <Button
                              onClick={() => {
                                setModalScore((val) => ({
                                  ...val,
                                  toggle: true,
                                }))
                              }}
                              style={{ height: 50, width: '100%' }}
                            >
                              {isEditScore ? 'Edit Score' : 'Add Score'}
                            </Button>
                          </Form.Item>
                        </Col>
                      )}
                    </>
                  )}

                  {!modal?.data?.isFinal && modal?.data?.id && (
                    <>
                      <Col span={24} lg={{ span: 6 }}>
                        <Form.Item label="Add Final Score">
                          <Button
                            onClick={() => {
                              setModalFinalScore((val) => ({
                                ...val,
                                toggle: true,
                              }))
                            }}
                            style={{ height: 50, width: '100%' }}
                          >
                            Add Final Score
                          </Button>
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Row>
              </div>
            </div>

            <div className="btn-confirm">
              <Popconfirm
                title="Are you sure to delete this Game?"
                onConfirm={confirmDelete}
                okText="Confirm"
                cancelText="Cancel"
              >
                <Button danger>Delete</Button>
              </Popconfirm>
              <button type="button" onClick={onCancel}>
                Cancel
              </button>
              {!modal?.data?.isFinal && (
                <button type="submit" disabled={loading}>
                  Save {loading && <Spin />}
                </button>
              )}
            </div>
          </div>
        </WrapModalGame>
      </Form>

      <ModalScore
        modal={modalScore}
        setModal={setModalScore}
        teamId={modal?.data?.id}
        onSubmitScore={onSubmitScore}
        disabled={modal?.data?.isFinal}
      />
      <ModalFinalScore
        modal={modalFinalScore}
        setModal={setModalFinalScore}
        teamId={modal?.data?.id}
        setRefreshGame={setRefreshGame}
        cancelGame={onCancel}
      />
    </Modal>
  )
}

export default ModalGame
