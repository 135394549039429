import { useCallback } from 'react'
import fetchHelper from 'helpers/FetchHelper'
import { API_PAYX } from 'config/constants/endpoints'

const useGetDeleteGame = () => {
  const getData = useCallback((id: string, cbs?: any, cbe?: any) => {
    try {
      fetchHelper
        .fetch(`${API_PAYX}/teams/${id}`, {
          method: 'DELETE',
        })
        .then(([resp, status]: any) => {
          if (status === 201 || status === 200) {
            if (cbs) cbs(resp.data)
          } else if (cbe) cbe(resp)
        })
    } catch (error) {
      console.error('useGetDeleteGame', error)
      cbe()
    }
  }, [])

  return getData
}

export default useGetDeleteGame
