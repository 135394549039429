import { useCallback } from 'react'
import fetchHelper from 'helpers/FetchHelper'
import { API_PAYX } from 'config/constants/endpoints'

const useGetEditFinalMatch = () => {
  const getData = useCallback((id: string, payload: any, cbs?: any, cbe?: any) => {
    try {
      fetchHelper
        .fetch(`${API_PAYX}/teams/finalMatch/${id}`, {
          method: 'PATCH',
          body: JSON.stringify(payload),
        })
        .then(([resp, status]: any) => {
          if (status === 201 || status === 200) {
            if (cbs) cbs(resp.data)
          } else if (cbe) cbe(resp)
        })
    } catch (error) {
      console.error('useGetEditFinalMatch', error)
      cbe()
    }
  }, [])

  return getData
}

export default useGetEditFinalMatch
